@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';





























































































































































@import 'src/css/mixins.scss';
.ms-dealer-autocomplete {
  .autocomplete {
    width: 100%;
    height: 50px;
    // border: 1px solid #017fff;
    border-radius: 3px;
    padding: 0 10px;
    color: #9fa9ad;
    @include font-primary(12px, 'medium');
    border: 1px solid rgba(0, 0, 0, 0.24);
    transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);

    &.suzuki {
      color: initial;
      font-size: 14px;
      font-weight: 400;
    }

    &:hover {
      border-color: #000;
    }

    &:focus,
    &:active {
      border: 2px solid #017fff;
    }
  }

  .scroll-find-dealership {
    max-height: 300px;
    overflow-x: auto;
  }

  .sucursal {
    height: auto;
    border: 1px solid rgba(159, 169, 173, 0.1);
    border-radius: 5px;
    background-color: rgba(159, 169, 173, 0.1);
    cursor: pointer;
    transition: 0.2s;

    &__name,
    &__option {
      height: auto;
    }
    &__name {
      .title {
        color: rgba(0, 64, 129, 1);
        line-height: 14px;
        @include font-primary(12px, 'semibold');
      }
      .address {
        color: rgba(75, 94, 101, 1);
        line-height: 16px;
        margin-top: 5px;
        @include font-primary(11px, 'semibold');
      }
    }
  }

  .label {
    color: #004081;
    opacity: 1;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 10px;

    &.suzuki {
      color: $suzuki;
      font-family: 'SuzukiPRORegular';
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      display: block;
      margin-bottom: 8px;
    }
  }

  .geolocalizacion {
    color: #017fff;
    text-decoration: underline;
    line-height: 18px;
    @include font-primary(10px, 'medium');
    i {
      text-decoration: none;
      font-size: 10px;
    }

    &.suzuki {
      color: $suzuki;
      font-family: 'SuzukiPRORegular';
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .badge {
    background-color: #f4f4f4;
    border-radius: 13px;
    padding: 5px 28px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    color: #4b5e65;
    position: relative;
    margin-bottom: 5px;

    img {
      width: 25px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
