@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';












































































































































































































































































































































































































































































.comunicado-page {
  padding-bottom: 64px;

  .image-wrapper {
    width: 100%;
    // height: 100svh;
    overflow: hidden;
    // margin-top: -60px;
    margin-bottom: 64px;

    img {
      width: 100%;
      height: 100%;
      display: block;
      // object-fit: cover;
    }
  }

  h1 {
    margin-bottom: 64px;
  }

  p {
    color: #4b5e65;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 64px;
    font-family: SuzukiPRORegular;
  }

  h2 {
    color: #000;
    font-weight: 600;
    text-align: left;
    margin: 0 0 32px 0;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: normal;
    font-family: SuzukiPRORegular;

    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 60px;
      background-color: $primary;
      margin-top: 8px;
    }
  }

  ul {
    padding-left: 19px;
    margin: 0;
    list-style: none;

    li {
      font-family: SuzukiPRORegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      position: relative;
      color: #4b5e65;

      + li {
        margin-top: 24px;
      }

      &::before {
        content: '';
        display: inline-block;
        background-color: #4b5e65;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        left: -16px;
        top: 11.5px;
      }
    }
  }

  ol {
    margin: 0;

    li {
      font-family: SuzukiPRORegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      position: relative;
      color: #4b5e65;

      &::marker {
        color: $primary;
      }

      + li {
        margin-top: 24px;
      }
    }
  }

  .requirements-container {
    border-radius: 5px;
    background: rgba(248, 248, 248, 0.67);
    padding: 64px;
    margin-bottom: 64px;
  }

  .comunicado-card {
    width: 100%;
    padding: 40px;
    border-radius: 10px;
    border: 1px solid #ddd;
    background: #fff;
    box-shadow: 0px 8px 32px 0px rgba(203, 203, 203, 0.16);

    img {
      width: 100%;
      height: 320px;
      object-fit: contain;
      margin-bottom: 32px;
      display: block;
    }

    p {
      margin-bottom: 40px;
    }
  }

  .cards-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    gap: 48px;
    margin-bottom: 64px;

    @media (max-width: $breakpoint-sm-max) {
      flex-flow: column nowrap;
    }
  }

  .form-container {
    width: 100%;
    max-width: 800px;
    padding: 64px 0;
    margin: 0 auto;

    h2 {
      font-size: 32px;
      line-height: 42px;
      font-family: SuzukiPRORegular;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    p {
      color: #4b5e65;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 48px;
      font-family: SuzukiPRORegular;
    }

    .label {
      color: #000;
      font-family: 'SuzukiPRORegular';
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      display: block;
      margin-bottom: 8px;
    }

    .privacy {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.dialog-confirm,
.dialog-call-suzuki {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  position: relative;

  h5 {
    color: #0f2832;
    line-height: 20px;
    @include font-primary(18px, 'semibold');
    margin-bottom: 20px;
    font-family: SuzukiPRORegular;
  }

  p {
    color: #4b5e65;
    @include font-primary(15px, 'regular');
    line-height: 25px;
    font-family: SuzukiPRORegular;
  }

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
