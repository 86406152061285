// Quasar Stylus Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Stylus variables found in Quasar's source Stylus files.

// Check documentation for full list of Quasar variables

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.
$--color-text-primary: #0f2832;
$--color-text-regular: #4b5e65;
$--color-text-placeholder: #9fa9ad;

$primary: #001151;
$secondary: #26a69a;
$accent: #004081;

$suzuki: #001151;

$positive: #5fa800;
$negative: #d40019;
$info: #bfe9ff;
$warning: #ffa600;
$text-color: #47546d;
$text-primary-color: #4b5e65;
$text-dark-color: #0f2832;

$breakpoint-desktop: 1280px;

$typography-font-family: 'SuzukiPRORegular';
$generic-border-radius: 0px;

@import 'src/css/mixins.scss'
